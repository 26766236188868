import { Box, useMediaQuery } from "@mui/material";
import useEmblaCarousel from "embla-carousel-react";
import NextImage from "next/image";
import { useCallback, useEffect, useState } from "react";

function useWindowDimensions() {
  const hasWindow = typeof window !== "undefined";

  const getWindowDimensions = useCallback(() => {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }, [hasWindow]);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  const handleResize = useCallback(() => {
    setWindowDimensions(getWindowDimensions());
  }, [setWindowDimensions, getWindowDimensions]);

  useEffect(() => {
    if (hasWindow) {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow, handleResize]);

  return windowDimensions;
}

export const ImageCarousel = ({ images }: { images: string[] }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: "keepSnaps",
    dragFree: true,
  });
  const isSmallDevice = useMediaQuery("(max-width:600px)");
  const dimensions = useWindowDimensions();

  const onThumbClick = useCallback(
    (index: number) => {
      if (!emblaApi || !emblaThumbsApi) return;
      emblaApi.scrollTo(index);
    },
    [emblaApi, emblaThumbsApi],
  );

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi],
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi],
  );

  const scrollTo = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi],
  );

  const onInit = useCallback((emblaApi: any) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;

    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
    emblaThumbsApi?.scrollTo?.(emblaApi.selectedScrollSnap());
  }, [emblaApi, emblaThumbsApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect();
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  return (
    <>
      <Box
        sx={{
          height: isSmallDevice ? "315px" : "400px",
          borderRadius: isSmallDevice ? "0" : "8px",
          objectFit: "fill",
          width: "100%",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
          }}
          className="embla"
          ref={emblaRef}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
            }}
            className="embla__container"
          >
            {images.map((image: string, index: number) => (
              <Box
                key={index}
                sx={{
                  flex: "0 0 100%",
                  minWidth: "100%",
                }}
                className="embla__slide"
              >
                <NextImage
                  onContextMenu={(e) => e.preventDefault()}
                  alt="Product image"
                  src={image}
                  layout="center"
                  style={{
                    objectFit: "contain",
                    width: "100%",
                  }}
                  width={
                    isSmallDevice && dimensions?.width ? dimensions.width : 600
                  }
                  height={isSmallDevice ? 315 : 400}
                />
              </Box>
            ))}
          </Box>
        </Box>
        {images?.length > 1 && !isSmallDevice && (
          <>
            <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
            <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
            <div
              className="embla__dots"
              style={{
                zIndex: 1,
                bottom: "1.2rem",
                position: "absolute",
                left: "0",
                right: "0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "fit-content",
                margin: "0 auto",
                background: "#1B1E3D",
                opacity: 0.5,
                borderRadius: "8px",
                padding: "3px 4px",
              }}
            >
              {scrollSnaps.map((_, index) => (
                <DotButton
                  key={index}
                  selected={index === selectedIndex}
                  onClick={() => scrollTo(index)}
                />
              ))}
            </div>
          </>
        )}
      </Box>
      {isSmallDevice && (
        <div
          style={{
            width: "100%",
            overflow: "hidden",
            padding: "16px",
            borderBottom: "1px solid rgba(12, 12, 36, 0.06)",
          }}
          className="embla-thumbs"
        >
          <div
            style={{
              width: "100%",
            }}
            className="embla-thumbs__viewport"
            ref={emblaThumbsRef}
          >
            <div
              className="embla-thumbs__container"
              style={{
                display: "flex",
                width: "100%",
                gap: "8px",
              }}
            >
              {images.map((image, index) => (
                <Thumb
                  onClick={() => onThumbClick(index)}
                  selected={index === selectedIndex}
                  imgSrc={image}
                  key={index}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const DotButton = (props: { selected: any; onClick: any }) => {
  const { selected, onClick } = props;

  return (
    <button
      style={{
        width: selected ? "8px" : "6px",
        height: selected ? "8px" : "6px",
        margin: "1px 3px",
        opacity: selected ? 1 : 0.5,
        backgroundColor: "#fff",
        borderStyle: "none",
        borderRadius: "50%",
        padding: 0,
      }}
      className={"embla__dot".concat(selected ? " embla__dot--selected" : "")}
      type="button"
      onClick={onClick}
    />
  );
};

export const PrevButton = (props: { enabled: boolean; onClick: any }) => {
  const { enabled, onClick } = props;

  return (
    <button
      style={{
        zIndex: 1,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: "50%",
        transform: "translateY(-50%) rotate(180deg)",
        cursor: "pointer",
        width: "40px",
        height: "40px",
        borderStyle: "none",
        borderRadius: "50%",
        left: "1.6rem",
        padding: 0,
      }}
      className="embla__button embla__button--prev"
      onClick={onClick}
      disabled={!enabled}
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m21.052 20-4.827-4.717a1.284 1.284 0 0 1 0-1.845 1.358 1.358 0 0 1 1.889 0l5.67 5.648c.51.508.51 1.32 0 1.828l-5.67 5.648c-.522.51-1.368.51-1.89 0a1.284 1.284 0 0 1 0-1.845L21.053 20z"
          fill="#1B1E3D"
          opacity={enabled ? 1 : 0.5}
          fillRule="evenodd"
        />
      </svg>
    </button>
  );
};

export const NextButton = (props: { enabled: boolean; onClick: any }) => {
  const { enabled, onClick } = props;

  return (
    <button
      style={{
        zIndex: 1,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer",
        width: "40px",
        height: "40px",
        borderStyle: "none",
        borderRadius: "50%",
        right: "1.6rem",
        padding: 0,
      }}
      className="embla__button embla__button--next"
      onClick={onClick}
      disabled={!enabled}
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m21.052 20-4.827-4.717a1.284 1.284 0 0 1 0-1.845 1.358 1.358 0 0 1 1.889 0l5.67 5.648c.51.508.51 1.32 0 1.828l-5.67 5.648c-.522.51-1.368.51-1.89 0a1.284 1.284 0 0 1 0-1.845L21.053 20z"
          fill="#1B1E3D"
          opacity={enabled ? 1 : 0.5}
          fillRule="evenodd"
        />
      </svg>
    </button>
  );
};

export const Thumb = (props: {
  selected: boolean;
  imgSrc: string;
  onClick: () => void;
}) => {
  const { selected, imgSrc, onClick } = props;

  return (
    <div
      className={"embla-thumbs__slide".concat(
        selected ? " embla-thumbs__slide--selected" : "",
      )}
      style={{
        overflow: "hidden",
        borderRadius: "6px",
        width: "60px",
        height: "54px",
        cursor: "pointer",
        border: selected
          ? "1px solid rgba(12, 12, 36, 0.5)"
          : "1px solid rgba(12, 12, 36, 0.06)",
      }}
    >
      <button
        onClick={onClick}
        className="embla-thumbs__slide__button"
        type="button"
        style={{
          height: "100%",
          width: "100%",
          borderStyle: "none",
          padding: 0,
          margin: 0,
        }}
      >
        <NextImage
          onContextMenu={(e) => e.preventDefault()}
          className="embla-thumbs__slide__img"
          src={imgSrc}
          alt="Thumbnail image"
          width={60}
          height={54}
          style={{
            width: "60px",
            height: "54px",
            objectFit: "contain",
          }}
        />
      </button>
    </div>
  );
};
